<template>
  <div>
    <div class="container-xl">
      <nav class="navbar navbar-expand-xxl navbar-dark justify-content-right">
        <!-- Brand -->

        <!-- Toggler/collapsibe Button -->
        <button
          class="navbar-toggler"
          type="button"
          data-bs-toggle="collapse"
          data-bs-target="#collapsibleNavbar"
        >
          <span class="navbar-toggler-icon"></span>
        </button>
        <!-- Navbar links -->
        <div class="collapse navbar-collapse" id="collapsibleNavbar">
          <ul class="navbar-nav">
            <li class="nav-item">
              <a class="nav-link" href="/">Home</a>
            </li>
            <li class="nav-item">
              <a class="nav-link" href="/beame/Company">Company</a>
            </li>
            <li class="nav-item">
              <a class="nav-link" href="/beame/platform">Platform</a>
            </li>
            <li class="nav-item">
              <a
                class="nav-link"
                href="/beame/Pricing"
                style="color: rgba(24, 163, 110, 1)"
                >Pricing</a
              >
            </li>
            <li class="nav-item">
              <a
                class="nav-link"
                href="/beame/RegisterNow"
                style="
                  border-radius: 343px;
                  background: rgba(24, 163, 110, 1);
                  color: #fff;
                "
                >Register Now</a
              >
            </li>
            <li>
              <img
                style="margin-left: 20px"
                src="https://beame.oss-cn-hongkong.aliyuncs.com/mobilePicture/America_menu.png"
                alt=""
              />
            </li>
          </ul>
        </div>
      </nav>
    </div>

    <div class="header">
      <img
        style="width: 100%; position: relative"
        src="https://beame.oss-accelerate.aliyuncs.com/mobilePicture/America_22.png?x-oss-process=style/style"
        alt=""
      />
      <div class="header_div">
        <h2 class="header_h2" style="color: rgba(24, 163, 110, 1)">
          Orthodesign
        </h2>
        <h2 class="header_h2">Streamline Your Orthodontic Experience</h2>
        <p class="header_p">
          Easy to use/Affordable Pricing/Quality Guarantee/Instant shippingEasy
          to use/Affordable Pricing/Quality Guarantee/Instant shipping
        </p>
      </div>
    </div>
    <div class="Pricing">
      <h2 class="Pricing_h2">
        <img
          class="products_img"
          src="https://beame.oss-cn-hongkong.aliyuncs.com/mobilePicture/America_15.png"
          alt=""
        />
        Pricing
        <img
          class="products_img"
          src="https://beame.oss-cn-hongkong.aliyuncs.com/mobilePicture/America_15.png"
          alt=""
        />
      </h2>
    </div>
    <div class="price">
      <div class="price_left">
        <div class="price_left_div">
          <h2 class="price_left_h2">
            Orthodontic <br />
            Design+Printing
          </h2>
          <img
            style="width: 70%"
            src="https://beame.oss-accelerate.aliyuncs.com/mobilePicture/America_26.png"
            alt=""
          />
        </div>

        <p style="margin-bottom: 30px">
          Our aligner printing service, which utilizes AI machine learning and
          the expertise of professional doctors, is priced at $7500. This cost
          covers the comprehensive process of aligner creation, from the initial
          analysis of the patient's dental structure to the final delivery of
          the aligners.
        </p>
        <h3 class="price_left_h3">
          7000 HKD <span class="price_left_span">per patient</span>
        </h3>
        <h3 class="price_left_h3">
          500 <span class="price_left_span">Design fee per patient</span>
        </h3>
        <button class="price_left_button">
          The pricing includes<br />
          the following components:
        </button>
        <p class="price_left_p">
          <button class="digit">1</button
          ><span style="color: rgba(24, 163, 110, 1)"> AI Analysis:</span> Our
          AI system employs advanced algorithms to analyze the patient's dental
          structure and generate precise aligner designs. This initial analysis
          ensures that the aligners are customized to address the specific
          orthodontic needs of the patient.
        </p>
        <p class="price_left_p">
          <button class="digit">2</button
          ><span style="color: rgba(24, 163, 110, 1)"
            >Professional Doctor Review:</span
          >
          Following the AI analysis, our team of experienced doctors
          meticulously reviews and fine-tunes the aligner designs. They verify
          the accuracy of the AI-generated designs and make any necessary
          adjustments to ensure optimal treatment outcomes.
        </p>
        <p class="price_left_p">
          <button class="digit">3</button
          ><span style="color: rgba(24, 163, 110, 1)"
            >Aligner Fabrication:
          </span>
          Once the aligner designs have been approved, we proceed with the
          fabrication process. This involves using state-of-the-art 3D printing
          technology and high-quality materials to create the aligners with
          precision and durability.
        </p>
        <p class="price_left_p">
          <button class="digit">4</button
          ><span style="color: rgba(24, 163, 110, 1)"
            >Treatment Monitoring:</span
          >
          Throughout the treatment period, our service includes regular
          check-ins and monitoring to track the progress of the patient's
          orthodontic treatment. This ensures that any necessary adjustments or
          modifications can be made to the aligners as needed.
        </p>
        <p class="price_left_p">
          <button class="digit">5</button
          ><span style="color: rgba(24, 163, 110, 1)">Customer Support:</span>
          We offer dedicated customer support to address any queries or concerns
          that may arise during the aligner treatment process. Our team is
          available to assist patients and provide guidance throughout their
          orthodontic journey.
        </p>
        <p class="price_left_p_2">
          It is important to note that the price of $7500 may vary depending on
          the complexity of the individual case and any additional services or
          treatments required. We strive to offer competitive pricing while
          delivering exceptional quality and personalized care through our
          aligner printing service.
        </p>
      </div>
      <div class="price_right">
        <div class="price_right_div">
          <h2 class="price_right_h2">
            Sleep Apnea <br />
            Design+Printing
          </h2>
          <img
            style="width: 70%; height: 135.42px"
            src="https://beame.oss-accelerate.aliyuncs.com/mobilePicture/America_27.png"
            alt=""
          />
        </div>

        <p style="margin-bottom: 30px">
          Our sleep apnea printing service, which combines AI machine learning
          with the expertise of professional doctors, is priced at $7000 HKD per
          patient. This cost covers a comprehensive range of services involved
          in the creation of customized sleep apnea devices.
        </p>
        <h3 class="price_right_h3">
          7000 HKD <span class="price_left_span">per patient</span>
        </h3>
        <h3 class="price_right_h3">
          500 <span class="price_left_span">Design fee per patient</span>
        </h3>
        <button class="price_right_button">
          The pricing includes <br />
          the following components:
        </button>
        <p class="price_left_p">
          <button class="digit">1</button
          ><span style="color: rgba(24, 163, 110, 1)"> AI Analysis:</span> Our
          AI system utilizes advanced algorithms to analyze the patient's sleep
          apnea condition based on relevant data and diagnostic information.
          This analysis helps generate personalized treatment plans and device
          designs tailored to the patient's specific needs.
        </p>
        <p class="price_left_p">
          <button class="digit">2</button
          ><span style="color: rgba(24, 163, 110, 1)"
            >Professional Doctor Consultation:</span
          >
          Our team of experienced doctors reviews the AI-generated analysis and
          treatment plans. They provide their expertise to ensure the accuracy
          and effectiveness of the device design, taking into consideration the
          patient's unique requirements and medical history.
        </p>
        <p class="price_left_p">
          <button class="digit">3</button
          ><span style="color: rgba(24, 163, 110, 1)"
            >Device Fabrication:
          </span>
          Once the treatment plan and device design have been finalized, we
          proceed with the fabrication process. This involves using advanced 3D
          printing technology and high-quality materials to create the sleep
          apnea device with precision and comfort.
        </p>
        <p class="price_left_p">
          <button class="digit">4</button
          ><span style="color: rgba(24, 163, 110, 1)"
            >Device Fitting and Adjustments:
          </span>
          After the device is manufactured, our professionals ensure a proper
          fit for the patient. They make any necessary adjustments to optimize
          the device's effectiveness and the patient's comfort during sleep.
        </p>
        <p class="price_left_p">
          <button class="digit">5</button
          ><span style="color: rgba(24, 163, 110, 1)"
            >Treatment Monitoring:</span
          >
          Our service includes ongoing monitoring and support throughout the
          treatment period. Our team will check in with the patient to assess
          the device's performance, address any concerns, and make necessary
          adjustments to enhance treatment outcomes.
        </p>
        <p class="price_left_p">
          <button class="digit">6</button
          ><span style="color: rgba(24, 163, 110, 1)">Customer Support:</span>
          We provide dedicated customer support to assist patients with any
          questions or issues that may arise during their sleep apnea treatment.
          Our team is available to provide guidance and ensure a smooth
          experience throughout the process.
        </p>
        <p class="price_left_p_2">
          Please note that the price of $7500 is an estimate and may vary
          depending on the complexity of the case and any additional services or
          treatments required. We strive to offer competitive pricing while
          delivering exceptional quality and personalized care through our sleep
          apnea printing service.
        </p>
      </div>
    </div>
    <div style="background-color: #e5faf2; padding: 40px 0">
      <div class="bottom_1 container-xl">
        <h2 class="bottom_h2">
          <img
            class="products_img"
            src="https://beame.oss-cn-hongkong.aliyuncs.com/mobilePicture/America_15.png"
            alt=""
          />
          Well received
          <img
            class="products_img"
            src="https://beame.oss-cn-hongkong.aliyuncs.com/mobilePicture/America_15.png"
            alt=""
          />
        </h2>
        <div class="Carousel">
          <el-carousel
            :autoplay="false"
            ref="carousel1"
            arrow="always"
            indicator-position="outside"
          >
            <el-carousel-item v-for="item in received" :key="item">
              <h3 class="Carousel_h3">{{ item.title }}</h3>
              <button class="Carousel_button">{{ item.button }}</button>
              <p class="Carousel_p">{{ item.content }}</p>
            </el-carousel-item>
          </el-carousel>
        </div>
      </div>
    </div>

    <div>
      <div class="query container-xl">
        <h3 class="confidence_title">
          <img
            class="query_img"
            src="https://beame.oss-cn-hongkong.aliyuncs.com/mobilePicture/America_15.png"
            alt=""
          />
          <span style="color: rgba(24, 163, 110, 1)">Q&A</span> Section
          <img
            class="query_img"
            src="https://beame.oss-cn-hongkong.aliyuncs.com/mobilePicture/America_15.png"
            alt=""
          />
        </h3>
        <van-collapse style="text-align: left" v-model="activeName" accordion>
          <van-collapse-item
            title="Q: How does the pricing for AlignSmile's aligner platform work?"
            name="1"
          >
            <div class="data">
              A: AlignSmile understands that affordability is important to their
              patients. They offer flexible payment options, including the
              possibility of paying for the aligner platform in installments.
              The specific details of the installment plans can be discussed
              with AlignSmile's customer support team. They will work with you
              to find a payment arrangement that suits your needs and ensures
              access to their high-quality aligner treatment.
            </div>
          </van-collapse-item>
          <van-collapse-item
            title="Q: Are there any additional costs associated with AlignSmile's aligner platform?"
            name="2"
          >
            <div class="data">
              A: AlignSmile understands that affordability is important to their
              patients. They offer flexible payment options, including the
              possibility of paying for the aligner platform in installments.
              The specific details of the installment plans can be discussed
              with AlignSmile's customer support team. They will work with you
              to find a payment arrangement that suits your needs and ensures
              access to their high-quality aligner treatment.
            </div>
          </van-collapse-item>
          <van-collapse-item
            title="Q: Can I pay for AlignSmile's aligner platform in installments?"
            name="3"
          >
            <div class="data">
              A: AlignSmile understands that affordability is important to their
              patients. They offer flexible payment options, including the
              possibility of paying for the aligner platform in installments.
              The specific details of the installment plans can be discussed
              with AlignSmile's customer support team. They will work with you
              to find a payment arrangement that suits your needs and ensures
              access to their high-quality aligner treatment.
            </div>
          </van-collapse-item>
          <van-collapse-item
            title="Q: Does AlignSmile's pricing include follow-up appointments and adjustments?"
            name="4"
          >
            <div class="data">
              A: AlignSmile understands that affordability is important to their
              patients. They offer flexible payment options, including the
              possibility of paying for the aligner platform in installments.
              The specific details of the installment plans can be discussed
              with AlignSmile's customer support team. They will work with you
              to find a payment arrangement that suits your needs and ensures
              access to their high-quality aligner treatment.
            </div>
          </van-collapse-item>
          <van-collapse-item
            title="Q: Is there a refund policy if I am not satisfied with AlignSmile's aligner platform?"
            name="5"
          >
            <div class="data">
              A: AlignSmile understands that affordability is important to their
              patients. They offer flexible payment options, including the
              possibility of paying for the aligner platform in installments.
              The specific details of the installment plans can be discussed
              with AlignSmile's customer support team. They will work with you
              to find a payment arrangement that suits your needs and ensures
              access to their high-quality aligner treatment.
            </div>
          </van-collapse-item>
        </van-collapse>
      </div>
    </div>
    <div class="bottom">
      <div class="bottom_div container-xl">
        <div>
          <img
            style="width: 135px"
            src="https://beame.oss-accelerate.aliyuncs.com/mobilePicture/America_23.png"
            alt=""
          />
        </div>
        <div style="text-align: left">
          <p style="margin-bottom: 10px">Home</p>
          <p style="margin-bottom: 10px">Company</p>
          <p style="margin-bottom: 10px">Platform</p>
          <p style="margin-bottom: 10px">Pricing</p>
        </div>
        <div style="text-align: left">
          <img
            style="width: 60px"
            src="https://beame.oss-accelerate.aliyuncs.com/mobilePicture/America_24.png"
            alt=""
          />
          <p style="font-size: 20px; margin-top: 10px">+852 5388 7318</p>
        </div>
        <div style="text-align: left">
          <img
            style="width: 60px"
            src="https://beame.oss-accelerate.aliyuncs.com/mobilePicture/America_25.png"
            alt=""
          />
          <p style="font-size: 18px; margin-top: 10px">
            On the third floor of Luohu District, <br />
            Shenzhen City, Guangdong Province
          </p>
        </div>
      </div>
    </div>
  </div>
</template>
          <script>
export default {
  components: {},
  data() {
    return {
      received: [
        {
          title: "AlignSmile",
          button: "Seamless Experience",
          content:
            "I recently used AlignSmile for my orthodontic treatment, and I couldn't be happier with the experience. The combination of AI machine learning and professional doctors ensures a seamless process from start to finish. The aligners were precisely tailored to my dental structure, and the treatment progress was closely monitored. AlignSmile made my journey to a straighter smile incredibly convenient and efficient.",
        },
        {
          title: "AlignSmile",
          button: "Seamless Experience",
          content:
            "I recently used AlignSmile for my orthodontic treatment, and I couldn't be happier with the experience. The combination of AI machine learning and professional doctors ensures a seamless process from start to finish. The aligners were precisely tailored to my dental structure, and the treatment progress was closely monitored. AlignSmile made my journey to a straighter smile incredibly convenient and efficient.",
        },
        {
          title: "AlignSmile",
          button: "Seamless Experience",
          content:
            "I recently used AlignSmile for my orthodontic treatment, and I couldn't be happier with the experience. The combination of AI machine learning and professional doctors ensures a seamless process from start to finish. The aligners were precisely tailored to my dental structure, and the treatment progress was closely monitored. AlignSmile made my journey to a straighter smile incredibly convenient and efficient.",
        },
      ],
      activeName: "1", //默认打开第一个折叠面板
    };
  },

  created() {},
  mounted() {},
  methods: {},
};
</script>
          <style scoped>
a:hover {
  color: #00e567 !important;
}

.navbar-dark .navbar-nav .nav-link {
  color: #000;
}
.navbar-collapse {
  flex-direction: row-reverse;
}
.navbar {
  margin-right: 30px;
}
.nav-item {
  padding: 0 15px;
}
.header {
  width: 100%;
  /* height: 460px; */
  opacity: 1;
  background: rgba(204, 204, 204, 0);

  text-align: center;
}

.header_div {
  width: 100%;
  text-align: center;
  position: absolute;
  top: 20%;
  left: 50%;
  transform: translate(-50%);
}
.header_h2 {
  font-size: 66px;
  font-weight: 600;
  margin-bottom: 30px;
}
.header_p {
  font-size: 22px;
  font-weight: 600;
  margin-bottom: 10px;
}
.bottom_1 {
  width: 100%;
  height: 540px;
  padding: 1px 0;
  text-align: center;
  background-image: url(https://beame.oss-accelerate.aliyuncs.com/mobilePicture/America_20.png?x-oss-process=style/style);
  background-size: 100% 100%;
}
.bottom_h2 {
  margin: 30px 0;
}
.Carousel {
  margin: auto;
  width: 950px;
  height: 300px;
  margin-top: 62px;
}
.Carousel_h3 {
  color: #fff;
  margin-top: 20px;
}
.Carousel_button {
  width: 299px;
  height: 30px;
  border: none;
  margin: 18px 0;
  border-radius: 238px;
  background: rgba(255, 255, 255, 1);
}
.Carousel_p {
  color: #fff;
  width: 80%;
  margin: auto;
}
.el-carousel >>> .el-carousel__arrow {
  background-color: transparent;
}
.el-carousel >>> .el-carousel__arrow i {
  color: #fff;
  font-size: 42px;
  font-weight: 900;
}
.el-carousel >>> .el-carousel__arrow--left {
  left: -9px;
}
.el-carousel >>> .el-carousel__arrow--right {
  right: -6px;
}

.el-carousel >>> .el-carousel__indicator--horizontal button {
  width: 10px;
  height: 10px;
  background: rgba(50, 62, 72, 0.3);
  border-radius: 50%;
  opacity: 0.5;
}
.el-carousel >>> .el-carousel__indicator--horizontal.is-active button {
  width: 10px;
  height: 10px;
  background: rgba(24, 163, 110, 1);
  border-radius: 50%;
  opacity: 0.5;
}
.query {
  width: 80%;
  margin: auto;
  text-align: center;
  padding: 60px 0 50px 0;
}
.confidence_title {
  margin: 80px;
}
.query_img {
  margin: 0 30px;
}
.data {
  margin-bottom: 10px;
  font-size: 17px;
}
.Pricing {
  text-align: center;
  margin: 80px 0;
}
.Pricing_h2 {
  font-size: 48px;
  font-weight: 700;
  letter-spacing: 0px;
  line-height: 62px;
  color: rgba(24, 163, 110, 1);
}
.price {
  display: flex;
  justify-content: space-evenly;
  flex-wrap: wrap;
  padding: 0 140px;
  padding-bottom: 100px;
  background: url(https://beame.oss-cn-hongkong.aliyuncs.com/mobilePicture/America_8.png)
    no-repeat bottom left;
}
.price_left {
  width: 460px;
}
.price_right {
  width: 460px;
}
.price_left_h2 {
  color: #fff;
  font-size: 38px;
  font-weight: 700;
  margin-bottom: 18px;
}

.price_left_div {
  width: 428px;
  height: 319px;
  text-align: center;
  padding-top: 40px;
  margin-bottom: 20px;
  border-radius: 21px;
  background: rgba(24, 163, 110, 1);
}
.price_right_div {
  width: 428px;
  height: 319px;
  text-align: center;
  padding-top: 40px;
  margin-bottom: 20px;
  border-radius: 21px;
  background: rgba(234, 235, 236, 0.75);
}
.price_left_h3 {
  font-size: 44px;
  font-weight: 700;
  letter-spacing: 0px;
  line-height: 40px;
  color: rgba(24, 163, 110, 1);
}
.price_left_span {
  font-size: 24px;
  color: #000;
  font-weight: 500;
}
.price_left_button {
  width: 370px;
  height: 81px;
  font-size: 22px;
  border: none;
  color: #fff;
  margin-bottom: 20px;
  border-radius: 0px 255px 255px 355px;
  background: rgba(24, 163, 110, 1);
  margin-top: 24px;
}
.digit {
  width: 25.34px;
  height: 26.21px;
  border: none;
  color: #fff;
  margin-right: 6px;
  pointer-events: none;
  border-radius: 20px 20px 0px 20px;
  background: rgba(24, 163, 110, 1);
}
.price_left_p {
  margin-left: 35px;
  text-indent: -35px;
}
.price_left_p_2 {
  margin-left: 35px;
  color: rgba(24, 163, 110, 1);
}
.price_right_h2 {
  font-size: 38px;
  font-weight: 700;
  margin-bottom: 18px;
}
.price_right_h3 {
  font-size: 44px;
  font-weight: 700;
  letter-spacing: 0px;
  line-height: 40px;
}
.price_right_button {
  width: 370px;
  height: 81px;
  border: none;
  font-size: 22px;
  color: rgba(0, 214, 132, 1);
  margin-bottom: 20px;
  border-radius: 0px 255px 255px 355px;
  background: rgba(50, 62, 72, 1);
  margin-top: 24px;
}
.van-collapse-item >>> .van-cell {
  font-size: 20px !important;
  font-weight: 700;
  /* background: rgba(50, 62, 72, 0.08); */
  margin-top: 20px;
  border-radius: 10px;
  padding: 16px;
  background-color: #fff !important;
  border-top: none;
}
.van-collapse >>> .van-collapse-item {
  border-bottom: solid 1px rgba(24, 163, 110, 1);
  /* border-top: solid 1px #fff; */
  background-color: #fff !important;
}
.van-collapse-item >>> .van-collapse-item__title--expanded {
  border-radius: 0 0 !important;
  border-bottom: solid 1px rgba(24, 163, 110, 1);
  /* border-top: solid 1px rgba(24, 163, 110, 1); */
}
.van-collapse-item >>> .van-collapse-item__content {
  /* background: rgba(50, 62, 72, 0.08); */
  border-radius: 0 0 10px 10px;
}
.bottom {
  width: 100%;
  padding: 40px 0;
  margin-top: 60px;
  text-align: center;
  color: #fff;
  background: rgba(54, 176, 130, 1);
}
.bottom_div {
  text-align: center;
  display: flex;
  justify-content: space-around;
  align-items: stretch;

  background: rgba(54, 176, 130, 1);
}
h2,
h3,
h4,
h5,
button,
a {
  font-family: "Omnium-Bold";
}
p,
span,
div {
  font-family: "Comfortaa-Bold";
}
</style>