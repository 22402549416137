<template>
  <div>
    <div class="containera">
      <div class="login-content">
        <h1 class="title">AI OrthoDesign</h1>

        <div class="container_2">
          <p class="identifying">Email</p>
          <el-input placeholder="please enter" v-model="Account" clearable>
          </el-input>
        </div>
        <div class="container_2">
          <p class="identifying">Password</p>
          <el-input
            placeholder="please enter"
            v-model="Pwd"
            show-password
          ></el-input>
        </div>

        <el-button class="Login" @click="Jump()">Login</el-button>
        <p>I forgot my password</p>
        <el-button class="Register">Register</el-button>
      </div>
    </div>
  </div>
</template>
    <script>
import { logon } from "@/api/outpatient";
import { JSEncrypt } from "jsencrypt";

export default {
  metaInfo: {
    link: [],
  },
  components: {},
  data() {
    return {
      // dataToEncrypt: "",
      publicKey:
        "-----BEGIN PUBLIC KEY-----MIIBIjANBgkqhkiG9w0BAQEFAAOCAQ8AMIIBCgKCAQEArNvBuIclnFYIT8HA2BSt+AVCb2Fq14q++FUSt1HIzBunuBP7kpUFCBs+qveL5Yf3icboPyuP4zLcMUcW2OFiq76V+0iFOpv+jP9sJO1pKRmAOzDiE22Q5Ce6joez5OQy0Plorf5auA9e0Sl9j1s2d2v6/4luhlbBJD10WO/E6Ub6rHsQUeYtuC3nS//SAJjdeRTfojnZY9Xlw7ZbDhA4DM2KTTQbJ71KKwm8dgxNWERqlodGkOfq+h9xig3hWmqZOhsS399Rw2xZqLe3gQP7/W9ttL3nMTtN+65NVB9quUOgx2IR9gvYTODUAvVPTh6UtJw0csX0YCH5H+h0ifmHdQIDAQAB-----END PUBLIC KEY-----",
      encryptedData: "",
      Account: "",
      Pwd: "",
    };
  },

  created() {
    // 清除所有本地存储
    // localStorage.clear();
    // if (
    //   localStorage.getItem("token") != "" &&
    //   localStorage.getItem("token") != null
    // ) {
    //   this.$router.push("/home");
    // }
  },
  mounted() {
    // 清除所有会话存储
    // localStorage.clear();
  },
  methods: {
    Jump() {
      this.$router.push("/zh-en/home");

      // 创建JSEncrypt实例并设置公钥
      const encrypt = new JSEncrypt();
      encrypt.setPublicKey(this.publicKey);

      var dataToEncrypt = {
        account: this.Account,
        password: this.Pwd,
      };
      // debugger;
      dataToEncrypt = JSON.stringify(dataToEncrypt);
      // 使用公钥加密数据
      var uresData = encrypt.encrypt(dataToEncrypt);
      // debugger;
      logon({ data: uresData }).then((res) => {
        if (res.code == 200) {
          localStorage.setItem("token", JSON.stringify(res.data.token));
          // localStorage.setItem("uuid", JSON.stringify(res.data.user.uuid));

          // localStorage.setItem(
          //   "DoctorName",
          //   JSON.stringify(res.data.user.name)
          // );
          // localStorage.setItem(
          //   "DoctorInformation",
          //   JSON.stringify(res.data.user)
          // );
          this.$router.push("/zh-en/home");
        } else {
          this.$message.error(res.msg);
        }
      });
    },
  },
};
</script>
    <style scoped>
body {
  height: 100%;
  width: 100%;
}
.login-content {
  max-width: 350px;
  margin: 0 auto 0 200px;
  border-radius: 20px;
  background-clip: padding-box;
  padding: 20px;
  border: 1px solid #eaeaea;
  -webkit-box-shadow: 0 0 25px #cac6c6;
  box-shadow: 0 0 25px #cac6c6;
  text-align: center;
  background: rgba(255, 255, 255, 0.76);
}
.containera {
  background-image: url(https://beame.oss-accelerate.aliyuncs.com/mobilePicture/America_28.png?x-oss-process=style/style);
  background-size: 100% 100%;
  height: 100%;
  padding-top: 200px;
  width: 100%;
  position: fixed;
}
.title {
  text-align: center;
}
.Login {
  width: 286px;
  height: 38px;
  color: #fff;
  margin-bottom: 10px;
  margin-top: 20px;
  border-radius: 560px;
  background: rgba(24, 163, 110, 1);
}
.Register {
  width: 286px;
  height: 38px;
  color: #fff;
  border-radius: 560px;
  background: rgba(50, 62, 72, 0.34);
}
.el-input {
  width: 100%;
}
.container_2 {
  width: 95%;
  text-align: left;
  margin: 10px auto 10px auto;
}
.identifying {
  margin: 0;
}
</style>