<template>
  <div></div>
</template>
<script>
export default {
  metaInfo: {
    link: [],
  },
  components: {},
  data() {
    return {};
  },
  mounted() {},
  methods: {},
};
</script>
<style scoped>
</style>