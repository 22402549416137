<template>
  <div>
    <h1 class="NotFound">404，您访问的页面不存在！</h1>
    <!-- <div style="text-align: center">
      <el-button @click="goHome" type="primary">返回首页</el-button>
      <el-button @click="goBack" type="primary">返回上一页</el-button> 
    </div> -->
  </div>
</template>
  <script>
export default {
  name: "NotFound",
  methods: {
    // goBack() {
    //   this.$router.go(-1);
    // },
    // goHome() {
    //   this.$router.push({ path: "/" });
    // },
  },
};
</script>
  <style scoped>
.NotFound {
  border-radius: 15px;
  background-clip: padding-box;
  margin: 90px auto;
  width: 350px;
  padding: 35px 35px 15px 35px;
  background: #fff;
  border: 1px solid #eaeaea;
  box-shadow: 0 0 25psx #cac6c6;
}
</style>
  
  