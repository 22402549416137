<template>
  <div>
    <div class="containera">
      <div class="login-content">
        <h1 class="title">AI OrthoDesign</h1>
        <br /><br />
        <div class="container">
          <p class="identifying">First Name*</p>
          <el-input placeholder="please enter" v-model="First" clearable>
          </el-input>
        </div>
        <div class="container">
          <p class="identifying">Last Name*</p>
          <el-input placeholder="please enter" v-model="Last" clearable>
          </el-input>
        </div>
        <div class="container">
          <p class="identifying">Clinic name</p>
          <el-input placeholder="please enter" v-model="Clinic" clearable>
          </el-input>
        </div>
        <div class="container">
          <p class="identifying">Phone number*</p>
          <el-input
            placeholder="please enter"
            v-model="Phone"
            clearable
            @input="Phone = Phone.replace(/[^\d]/g, '')"
          >
          </el-input>
        </div>
        <div class="container">
          <p class="identifying">Country*</p>
          <el-input placeholder="please enter" v-model="Country" clearable>
          </el-input>
        </div>
        <div class="container">
          <p class="identifying">City</p>
          <el-input placeholder="please enter" v-model="City" clearable>
          </el-input>
        </div>
        <div class="container">
          <p class="identifying">Post Code</p>
          <el-input placeholder="please enter" v-model="Post" clearable>
          </el-input>
        </div>
        <div class="container">
          <p class="identifying">Email*</p>
          <el-input placeholder="please enter" v-model="Email" clearable>
          </el-input>
        </div>
        <div class="container_2">
          <p class="identifying">Address</p>
          <el-input placeholder="please enter" v-model="Address" clearable>
          </el-input>
        </div>

        <div class="container_2">
          <p class="identifying">How did you hear about us*</p>
          <el-select v-model="value" placeholder="Please select">
            <el-option
              v-for="item in options"
              :key="item.value"
              :label="item.label"
              :value="item.value"
            >
            </el-option>
          </el-select>
        </div>

        <div class="container">
          <p class="identifying">Password*</p>
          <el-input
            placeholder="Enter"
            v-model="Password"
            clearable
            show-password
          >
          </el-input>
        </div>
        <div class="container">
          <p class="identifying">Confirm password*</p>
          <el-input
            placeholder="Enter"
            v-model="Confirm"
            clearable
            show-password
          >
          </el-input>
        </div>
        <br />

        <p style="margin-left: 20px">
          <input type="checkbox" checked="checked" /> I have read and accept the
          privacy policy <span style="color: rgba(24, 163, 110, 1)">Read</span>
        </p>
        <br />
        <p style="margin: auto">*Compulsory fields</p>
        <el-button class="Login" @click="Jump()">Register now!</el-button>
      </div>
    </div>
  </div>
</template>
      <script>
import { register } from "@/api/outpatient";
import { JSEncrypt } from "jsencrypt";

export default {
  metaInfo: {
    link: [],
  },
  components: {},
  data() {
    return {
      // dataToEncrypt: "",
      publicKey:
        "-----BEGIN PUBLIC KEY-----MIIBIjANBgkqhkiG9w0BAQEFAAOCAQ8AMIIBCgKCAQEArNvBuIclnFYIT8HA2BSt+AVCb2Fq14q++FUSt1HIzBunuBP7kpUFCBs+qveL5Yf3icboPyuP4zLcMUcW2OFiq76V+0iFOpv+jP9sJO1pKRmAOzDiE22Q5Ce6joez5OQy0Plorf5auA9e0Sl9j1s2d2v6/4luhlbBJD10WO/E6Ub6rHsQUeYtuC3nS//SAJjdeRTfojnZY9Xlw7ZbDhA4DM2KTTQbJ71KKwm8dgxNWERqlodGkOfq+h9xig3hWmqZOhsS399Rw2xZqLe3gQP7/W9ttL3nMTtN+65NVB9quUOgx2IR9gvYTODUAvVPTh6UtJw0csX0YCH5H+h0ifmHdQIDAQAB-----END PUBLIC KEY-----",
      encryptedData: "",
      First: "",
      Last: "",
      Clinic: "",
      Phone: "",
      Country: "",
      City: "",
      Post: "",
      Address: "",
      Email: "",
      Password: "",
      Confirm: "",
      options: [
        {
          value: "Doctor referral (discount in future)",
          label: "Doctor referral (discount in future)",
        },
        {
          value: "Online ads",
          label: "Online ads",
        },
        {
          value: "Other (type)",
          label: "Other (type)",
        },
      ],
      value: "",
    };
  },

  created() {},
  mounted() {},
  methods: {
    Jump() {
      this.$router.push("/");

      // if (/^.{2,23}@.{2,20}\..{1,18}$/.test(this.Email) == false) {
      //   this.$message({
      //     message: "Email format is incorrect",
      //     type: "warning",
      //   });
      //   return;
      // }

      // 创建JSEncrypt实例并设置公钥
      const encrypt = new JSEncrypt();
      encrypt.setPublicKey(this.publicKey);

      // debugger;
      var dataToEncrypt = this.Password;
      // 使用公钥加密数据
      var urespassword = encrypt.encrypt(dataToEncrypt);
      var data = {
        first_name: this.First,
        last_name: this.Last,
        clinic_name: this.clinic,
        phone: this.Phone,
        country: this.Country,
        email: this.Email,
        password: urespassword,
        city: this.City,
        post_code: this.Post,
        address: this.Address,
        source_info: this.value,
      };

      // debugger;
      register(data).then((res) => {
        if (res.code == 200) {
          this.$router.push("/");
        } else {
          this.$message.error(res.msg);
        }
      });
    },
  },
};
</script>
      <style scoped>
body {
  height: 100%;
  width: 100%;
}
.login-content {
  max-width: 450px;
  margin: 0 auto 0 200px;
  border-radius: 20px;
  background-clip: padding-box;
  padding: 20px;
  border: 1px solid #eaeaea;
  -webkit-box-shadow: 0 0 25px #cac6c6;
  box-shadow: 0 0 25px #cac6c6;
  text-align: center;
  background: rgba(255, 255, 255, 0.76);
  display: flex;
  flex-wrap: wrap;
}
.containera {
  background-image: url(https://beame.oss-accelerate.aliyuncs.com/mobilePicture/America_28.png?x-oss-process=style/style);
  background-size: 100% 100%;
  height: 100%;
  padding-top: 80px;
  width: 100%;
  position: fixed;
}
.title {
  text-align: center;
  margin: 0 auto 20px auto;
}
.Login {
  width: 286px;
  height: 38px;
  margin: 10px auto 0 auto;
  color: #fff;
  border-radius: 560px;
  background: rgba(24, 163, 110, 1);
}
.Register {
  width: 286px;
  height: 38px;
  margin: auto;
  border-radius: 560px;
  background: rgba(50, 62, 72, 0.34);
}
.el-input {
  width: 100%;
}
.el-input__inner {
  border-radius: 8px;
}
.container {
  width: 50%;
  text-align: left;
  margin-bottom: 10px;
}
.container_2 {
  width: 95%;
  text-align: left;
  margin: 5px auto 5px auto;
}
.identifying {
  margin: 0;
}
.el-select {
  width: 100%;
}
</style>