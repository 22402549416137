<template>
  <div style="height: 100%; width: 100%; position: fixed">
    <div class="login">
      <div class="box">
        <div class="desc">
          <div class="desc-information">
            <div>
              <!-- <img
                src="http://cameo.eset3d.com/static/img/img.563bd17.png"
                alt=""
              /> -->
            </div>
            <div class="desc-info-title">
              <span>病例管理系統</span>
            </div>
            <div class="desc-info-content">
              <p>一個以醫生為覈心的病歷管理、醫技互動平臺。</p>
              <p>完善病歷登記和稽核功能，即時瀏覽排牙設計效果，</p>
              <p>方便醫生與技工間溝通交流的快速回應系統。</p>
            </div>
          </div>
        </div>
        <div class="login-form">
          <div class="login-form-content">
            <div class="form-content-info">
              <div class="form-title" style="text-align: center">
                <!-- <img
                  src="http://cameo.eset3d.com/static/img/logo-new.792e6d5.png"
                  style="width: 80%"
                /> -->
                be@me
              </div>
              <form
                autocomplete="off"
                class="form-area ivu-form ivu-form-label-right"
              >
                <div>
                  <div class="user ivu-form-item">
                    <div class="ivu-form-item-content">
                      <div
                        class="userName ivu-input-wrapper ivu-input-wrapper-default ivu-input-type-text"
                      >
                        <input
                          autocomplete="off"
                          spellcheck="false"
                          type="text"
                          placeholder="請輸入帳號"
                          class="ivu-input ivu-input-default"
                          v-model="account"
                        />
                      </div>
                    </div>
                  </div>
                  <div class="passwordForm ivu-form-item">
                    <div class="ivu-form-item-content">
                      <div
                        class="password ivu-input-wrapper ivu-input-wrapper-default ivu-input-type-password"
                      >
                        <input
                          autocomplete="off"
                          spellcheck="false"
                          type="password"
                          placeholder="請輸入密碼"
                          class="ivu-input ivu-input-default"
                          v-model="password"
                        />
                      </div>
                    </div>
                  </div>
                  <label
                    class="auto_login ivu-checkbox-wrapper ivu-checkbox-default"
                  >
                    <!-- <span class="ivu-checkbox"
                      ><span class="ivu-checkbox-inner"></span>
                      <input type="checkbox" class="ivu-checkbox-input"
                    /></span>
                    自动登录 -->
                  </label>
                  <div style="display: inline-block; float: right">
                    <a href="#" class="forgive-pass">忘記密碼？</a>
                  </div>
                  <div class="submit ivu-form-item">
                    <div class="ivu-form-item-content">
                      <button
                        @click="Jump()"
                        type="button"
                        class="loginBtn ivu-btn ivu-btn-primary ivu-btn-long checkLogin"
                      >
                        <span> 登 入 </span>
                      </button>
                    </div>
                  </div>
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>
      <!-- <div class="footer">
        <ul>
          <li></li>
          <li class="copyright">
            <span style="padding-right: 40px"></span>
          </li>
        </ul>
      </div> -->
    </div>
  </div>
</template>
  <script>
import { Login } from "@/api/outpatient";
import { JSEncrypt } from "jsencrypt";
import axios from "axios";

export default {
  metaInfo: {
    link: [],
  },
  components: {},
  data() {
    return {
      // dataToEncrypt: "",
      publicKey:
        "-----BEGIN PUBLIC KEY-----MIIBIjANBgkqhkiG9w0BAQEFAAOCAQ8AMIIBCgKCAQEA0swwnhUrSTZmQWorUa8Kqd97Q/M6esaNTlnEebpZ/O7RfcxHFcsIzMm132OlxgyGdfsXRHZdTnS29w902sGVSa/Se4YlVgKfEOchLe8ClpTa57HM6U8sEnzmcxukiFlTTpnXzze6IUlzv+UN9MXJduY3koWXKwd9501wsC7tk9pG6UMX0Hr8hltN34bQ4gKk/t24BPp5Kpab58rduqkOc3n/wO773slb31SeM414WknqcDT6T4H2rZgUdIO/ZTdzANVqG7uh/i8JCrLAuQD7OM4e79JbDT/vLjDiUNtn6wJBZN1bMvC71mLNLDFU+xNBhqe8tpT6jeMIYBinlelQ6wIDAQAB-----END PUBLIC KEY-----",
      encryptedData: "",
      account: "",
      password: "",
    };
  },

  created() {
    // 清除所有本地存储
    localStorage.clear();
    // if (sessionStorage.getItem("token")) {
    //   this.$router.push("/home");
    // }
  },
  mounted() {
    // 清除所有会话存储
    // localStorage.clear();
  },
  methods: {
    Jump() {
      // 创建JSEncrypt实例并设置公钥
      const encrypt = new JSEncrypt();
      encrypt.setPublicKey(this.publicKey);

      var dataToEncrypt = {
        account: this.account,
        password: this.password,
      };
      debugger;
      dataToEncrypt = JSON.stringify(dataToEncrypt);
      // 使用公钥加密数据
      var uresData = encrypt.encrypt(dataToEncrypt);
      // debugger;
      Login({ data: uresData }).then((res) => {
        if (res.code == 200) {
          sessionStorage.setItem("token", JSON.stringify(res.data.token));
          localStorage.setItem("uuid", JSON.stringify(res.data.user.uuid));

          localStorage.setItem(
            "DoctorName",
            JSON.stringify(res.data.user.name)
          );
          localStorage.setItem(
            "DoctorInformation",
            JSON.stringify(res.data.user)
          );
          this.$router.push("/home");
        } else {
          this.$message.error(res.msg);
        }
      });
    },
  },
};
</script>
  <style scoped>
html,
body {
  margin: 0;
  height: 100%;
}
.login {
  width: 100%;
  height: 100%;
  min-width: 1000px;
  background: url(https://beame.oss-cn-hongkong.aliyuncs.com/mobilePicture/cases-1.png)
    no-repeat;
  background-size: 100% 100%;
}
.box {
  width: 100%;
  min-height: 800px;
  position: relative;
  min-width: 1280px;
  height: calc(100% - 48px);
}
.desc {
  clear: both;
  float: left;
  width: 50%;
  height: 100%;
  padding-top: 26%;
}
.desc-information {
  width: 717px;
  /* height: 440px; */
  text-align: center;
  float: right;
}
.desc-info-title {
  margin-top: 30px;
  font-size: 32px;
  font-weight: 400;
  text-align: center;
  /* color: #ffffff; */
}
.desc-info-content {
  margin-top: 16px;
  font-size: 18px;
  text-align: left;
  /* color: #ffffff; */
  /* padding-left: 146px; */
  text-align: center;
}
.login-form {
  float: right;
  width: 50%;
  /* height: 500px; */
  height: 100%;
  padding-top: 11%;
}
.login-form-content {
  border-radius: 23px;
  margin-left: 190px;
  width: 400px;
  background: #fff;
  padding-bottom: 20px;
  box-shadow: 2px 2px 20px rgba(0, 0, 0, 0.15);
}
.form-title {
  font-size: 50px;
  font-weight: 700;
  letter-spacing: 0px;
  line-height: 61px;
  color: rgba(17, 184, 120, 1);
  text-align: center;
  vertical-align: top;
  font-family: "Omnium-Bold";
}
.form-content-info {
  /* width: 100%; */
  padding: 52px 50px 0;
}
.ivu-form-item-content {
  position: relative;
  line-height: 32px;
  font-size: 14px;
}
.loginBtn {
  width: 100%;
  height: 50px;
  opacity: 1;
  border-radius: 256px;
  background: rgba(17, 184, 120, 1);
  box-shadow: 2px 2px 6px rgba(0, 0, 0, 0.1);
  cursor: pointer;
  border: none;
  font-size: 22px;
  color: #fff;
}

.form-area {
  margin-top: 30px;
}

.form-area .ivu-form-item.passwordForm {
  margin-bottom: 24px;
}
.ivu-input {
  width: 100%;
  border: 2px solid rgba(17, 184, 120, 1);
  border-radius: 12px;
  padding: 5px 15px;
  margin: 5px 0;
}
.auto_login {
  margin-bottom: 50px;
  float: left;
}
.ivu-checkbox-wrapper {
  cursor: pointer;
  font-size: 14px;
  display: inline-block;
  margin-right: 8px;
}
.form-area .ivu-form-item {
  margin-bottom: 20px;
  clear: both;
  height: 60px;
}
.header-set {
  height: 14px;
}
.footer {
  /* width: 100%; */
  height: 48px;
  /* background: #818CA8; */
  margin: 0 auto;
  padding: 50px 260px;
  font-size: 14px;
  font-family: PingFangSC-Regular;
  /* color: rgba(255, 255, 255, 1); */
  line-height: 22px;
  /* min-width: 1280px; */
}
.footer ul {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: justify;
  -ms-flex-pack: justify;
  justify-content: space-between;
}
.footer ul li {
  list-style: none;
  height: 14px px;
  line-height: 14px;
}
.footer ul li a,
.footer .copyright a {
  height: 14px;
  display: inline-block;
  line-height: 14px;
}
.footer .copyright {
  clear: both;
  /* margin-top: 66px
px
; */
  height: 14px;
  line-height: 14px;
}
.footer .copyright a {
  color: #fff;
}
h2,
h3,
h4,
h5,
button,
a {
  font-family: "GenSenMaruGothicTW-Bold";
}
p,
span,
div {
  font-family: "GenSenMaruGothicTW-Regular";
}
</style>