<template>
  <div>
    <div class="container-xl">
      <nav class="navbar navbar-expand-xxl navbar-dark justify-content-right">
        <!-- Brand -->

        <!-- Toggler/collapsibe Button -->
        <button
          class="navbar-toggler"
          type="button"
          data-bs-toggle="collapse"
          data-bs-target="#collapsibleNavbar"
        >
          <span class="navbar-toggler-icon"></span>
        </button>
        <!-- Navbar links -->
        <div class="collapse navbar-collapse" id="collapsibleNavbar">
          <ul class="navbar-nav">
            <li class="nav-item">
              <a class="nav-link" href="/" style="color: rgba(24, 163, 110, 1)"
                >Home</a
              >
            </li>
            <li class="nav-item">
              <a class="nav-link" href="/beame/Company">Company</a>
            </li>
            <li class="nav-item">
              <a class="nav-link" href="/beame/platform">Platform</a>
            </li>
            <li class="nav-item">
              <a class="nav-link" href="/beame/Pricing">Pricing</a>
            </li>
            <li class="nav-item">
              <a
                class="nav-link"
                href="/beame/RegisterNow"
                style="
                  border-radius: 343px;
                  background: rgba(24, 163, 110, 1);
                  color: #fff;
                "
                >Register Now</a
              >
            </li>
            <li>
              <img
                style="margin-left: 20px"
                src="https://beame.oss-cn-hongkong.aliyuncs.com/mobilePicture/America_menu.png"
                alt=""
              />
            </li>
          </ul>
        </div>
      </nav>
    </div>

    <div class="header">
      <div>
        <el-carousel indicator-position="outside" height="720px">
          <el-carousel-item>
            <img
              style="width: 100%; position: relative"
              src="https://beame.oss-accelerate.aliyuncs.com/mobilePicture/America_1.png?x-oss-process=style/style"
              alt=""
            />
            <div class="container-xl">
              <div class="header_div">
                <h2
                  class="header_h2"
                  style="color: rgba(24, 163, 110, 1); font-size: 42px"
                >
                  beamealign
                </h2>
                <h2 class="header_h2">AI OrthoDesign</h2>
                <p class="header_p">
                  OrthoDesign : The world's no.1 orthdontic design and priting
                  platformWelcome to OrthoDeisgn your premier destination for
                  cutting-edge AI orthodontic Deisgn and printing Services.
                </p>
                <div>
                  <button @click="Jump('/Login')" class="header_button">
                    Sign up to Ortho Design</button
                  ><button
                    @click="Jump('/beame/RegisterNow')"
                    class="header_button_2"
                  >
                    Register Now
                  </button>
                </div>
              </div>
            </div>
          </el-carousel-item>
          <el-carousel-item>
            <img
              style="width: 100%; position: relative"
              src="https://beame.oss-accelerate.aliyuncs.com/mobilePicture/America_1.png?x-oss-process=style/style"
              alt=""
            />
            <div class="container-xl">
              <div class="header_div">
                <h2
                  class="header_h2"
                  style="color: rgba(24, 163, 110, 1); font-size: 42px"
                >
                  beame aligners
                </h2>
                <h2 class="header_h2">AI OrthoDesign</h2>
                <p class="header_p">
                  OrthoDesign : The world's no.1 orthdontic design and priting
                  platformWelcome to OrthoDeisgn your premier destination for
                  cutting-edge AI orthodontic Deisgn and printing Services.
                </p>
                <div>
                  <button @click="Jump('/Login')" class="header_button">
                    Sign up to Ortho Design</button
                  ><button
                    @click="Jump('/beame/RegisterNow')"
                    class="header_button_2"
                  >
                    Register Now
                  </button>
                </div>
              </div>
            </div>
          </el-carousel-item>
        </el-carousel>
      </div>
    </div>
    <div class="evaluate container-xl">
      <img
        class="evaluate_img_2"
        src="https://beame.oss-cn-hongkong.aliyuncs.com/mobilePicture/America_2.png"
        alt=""
      />
    </div>
    <div class="confidence_free" style="margin-bottom: 100px">
      <div class="confidence_free_div">
        <video width="520" height="400" controls>
          <source src="movie.mp4" type="video/mp4" />
          <source src="movie.ogg" type="video/ogg" />
          您的浏览器不支持Video标签。
        </video>
      </div>
      <div class="confidence_free_div_2">
        <h4 class="confidence_free_h2">
          "Enhance Your Patients' Smiles: <br />
          Affordable Aligner Design and Printing<br />
          Solution for Doctors at Just
          <span style="color: rgba(24, 163, 110, 1)">7500HKD!</span>"
        </h4>
        <p style="color: rgba(50, 62, 72, 1)">
          With our advanced technology and expertise, we have developed a
          streamlined process that combines the art and science of orthodontics
          with the unlimited potential of AI. Our team of skilled professionals
          works closely with doctors to create customized treatment plans and
          design orthodontic appliances that cater to the unique needs of each
          patient.
        </p>
        <button class="confidence_free_button">Sign Up Now</button> <br />
        <a href="#" style="color: rgba(24, 163, 110, 1)"
          >Watch Video &gt;&gt;</a
        >
      </div>
    </div>
    <div class="beVexed">
      <h3 class="beVexed_title">
        Democratizing Affordable Invisible Alignment Treatment:
      </h3>
      <h3 class="beVexed_title_2">
        Empowering Clinics with Accessible and Affordable AI Solutions.
      </h3>
      <div class="beVexed_step">
        <div class="beVexed_step_div">
          <img
            class="beVexed_step_img"
            src="https://beame.oss-cn-hongkong.aliyuncs.com/mobilePicture/America_3.png"
            alt=""
          />
          <p class="beVexed_step_p">
            Sign up to OrthoDesign WebsiteCreate patient file
          </p>
        </div>
        <div class="beVexed_step_div">
          <img
            class="beVexed_step_img"
            src="https://beame.oss-cn-hongkong.aliyuncs.com/mobilePicture/America_4.png"
            alt=""
          />
          <p class="beVexed_step_p">
            Scan your patient with a3DscannerUpload your treatmetn
          </p>
        </div>
        <div class="beVexed_step_div">
          <img
            class="beVexed_step_img"
            src="https://beame.oss-cn-hongkong.aliyuncs.com/mobilePicture/America_5.png"
            alt=""
          />
          <p class="beVexed_step_p">
            Receive your Al machining treatment PlanConfirm your treatment
          </p>
        </div>
        <div class="beVexed_step_div">
          <img
            class="beVexed_step_img"
            src="https://beame.oss-cn-hongkong.aliyuncs.com/mobilePicture/America_6.png"
            alt=""
          />
          <p class="beVexed_step_p">
            Pay for the aligner Box and AlignersStripe/Credit Card
          </p>
        </div>
      </div>
      <button class="beVexed_button">Recieve the Patient Box</button><br />
      <p style="color: rgba(24, 163, 110, 1)">
        Moniter the patient through patient app
      </p>
    </div>
    <div class="confidence_free">
      <div class="confidence_free_div">
        <img
          class="confidence_free_img"
          src="https://beame.oss-cn-hongkong.aliyuncs.com/mobilePicture/America_7.png"
          alt=""
        />
      </div>
      <div class="confidence_free_div_2">
        <h4 class="confidence_free_h4">
          Digital Treatment Managment<br />
          Platform
        </h4>
        <div
          style="
            margin: 0 0 30px 0;
            width: 120px;
            height: 6px;
            background-color: rgba(24, 163, 110, 0.9);
          "
        ></div>
        <p>
          OrthoDock: Revolutionizing Orthodontic Practice ManagementStreamline
          Your Workflow with Our Digital Case Management Platform, Empowering
          Dentists to Customize Client Treatments, Manage Orders, Logistics, and
          Payment Fulfillment - All in One Convenient Solution.
        </p>
      </div>
    </div>

    <div class="Digital">
      <h3 class="Digital_h3">
        <span style="color: rgba(24, 163, 110, 1)">Digital</span> Treatment
        <br />
        Managment Platform
      </h3>
      <div class="confidence_free">
        <div class="confidence_free_div">
          <img
            class="confidence_free_img"
            src="https://beame.oss-cn-hongkong.aliyuncs.com/mobilePicture/America_10.png"
            alt=""
          />
        </div>
        <div class="confidence_free_div_2">
          <p class="confidence_free_p" style="color: rgba(24, 163, 110, 1)">
            Rest assured, our products are crafted with utmost care and are
            guaranteed to be BPA-free, ensuring your health and well-being.
          </p>
        </div>
      </div>
      <div class="confidence_free_2">
        <div class="confidence_free_div_2">
          <p class="confidence_free_p">
            "Embrace peace of mind with our products, as each one proudly bears
            the CE marking, a testament to their compliance with stringent
            safety and quality standards."
          </p>
        </div>
        <div class="confidence_free_div">
          <img
            class="confidence_free_img_2"
            src="https://beame.oss-cn-hongkong.aliyuncs.com/mobilePicture/America_11.png"
            alt=""
          />
        </div>
      </div>
      <div class="confidence_free">
        <div class="confidence_free_div">
          <img
            class="confidence_free_img"
            src="https://beame.oss-cn-hongkong.aliyuncs.com/mobilePicture/America_12.png"
            alt=""
          />
        </div>
        <div class="confidence_free_div_2">
          <p class="confidence_free_p" style="color: rgba(24, 163, 110, 1)">
            "You can trust in the excellence of our products, as they have
            earned the esteemed FDA approval, assuring you of their safety,
            efficacy, and compliance with the highest regulatory standards."
          </p>
        </div>
      </div>
      <div class="confidence_free_2">
        <div class="confidence_free_div_2">
          <p>
            "Feel reassured in your choice with our products, as they hold the
            prestigious ISO 13485 certification, a testament to our unwavering
            commitment to delivering exceptional quality, reliability, and
            compliance with international medical device standards."
          </p>
        </div>
        <div class="confidence_free_div">
          <img
            class="confidence_free_img_2"
            src="https://beame.oss-cn-hongkong.aliyuncs.com/mobilePicture/America_13.png"
            alt=""
          />
        </div>
      </div>
      <div class="confidence_free_3">
        <p>
          <span style="color: rgba(24, 163, 110, 1)">Our designs</span> are
          meticulously crafted by esteemed orthodontic professionals, ensuring
          the utmost caliber and safety in aligner .
        </p>
        <p>
          <span style="color: rgba(24, 163, 110, 1)">Our shipping process</span>
          outpaces our competitors by an impressive 80%, exemplifying our
          commitment to swift and efficient delivery.
        </p>
        <p>
          Our designs completion speeds boast an impressive
          <span style="color: rgba(24, 163, 110, 1)"
            >completion time of a single day.</span
          >
        </p>
        <h4 style="color: rgba(24, 163, 110, 1)">
          design available in the industry.
        </h4>
      </div>
    </div>
    <div>
      <div class="summary">
        <img
          width="100%"
          src="https://beame.oss-accelerate.aliyuncs.com/mobilePicture/America_14.png?x-oss-process=style/style"
          alt=""
        />
        <!-- <div class="summary_div">
          <h3 class="summary_h3">
            Welcome to the <br />
            Future of Orthodontics: <br />
            Let AI Transform and Streamline <br />
            Your Treatments!
          </h3>
          <button class="header_button">Sign Up Now</button>
        </div> -->
      </div>
    </div>

    <div>
      <div class="query container-xl">
        <h3 class="confidence_title">
          <img
            class="query_img"
            src="https://beame.oss-cn-hongkong.aliyuncs.com/mobilePicture/America_15.png"
            alt=""
          />
          <span style="color: rgba(24, 163, 110, 1)">Q&A</span> Section
          <img
            class="query_img"
            src="https://beame.oss-cn-hongkong.aliyuncs.com/mobilePicture/America_15.png"
            alt=""
          />
        </h3>
        <van-collapse style="text-align: left" v-model="activeName" accordion>
          <van-collapse-item
            title="Q: How does the pricing for AlignSmile's aligner platform work?"
            name="1"
          >
            <div class="data">
              A: AlignSmile understands that affordability is important to their
              patients. They offer flexible payment options, including the
              possibility of paying for the aligner platform in installments.
              The specific details of the installment plans can be discussed
              with AlignSmile's customer support team. They will work with you
              to find a payment arrangement that suits your needs and ensures
              access to their high-quality aligner treatment.
            </div>
          </van-collapse-item>
          <van-collapse-item
            title="Q: Are there any additional costs associated with AlignSmile's aligner platform?"
            name="2"
          >
            <div class="data">
              A: AlignSmile understands that affordability is important to their
              patients. They offer flexible payment options, including the
              possibility of paying for the aligner platform in installments.
              The specific details of the installment plans can be discussed
              with AlignSmile's customer support team. They will work with you
              to find a payment arrangement that suits your needs and ensures
              access to their high-quality aligner treatment.
            </div>
          </van-collapse-item>
          <van-collapse-item
            title="Q: Can I pay for AlignSmile's aligner platform in installments?"
            name="3"
          >
            <div class="data">
              A: AlignSmile understands that affordability is important to their
              patients. They offer flexible payment options, including the
              possibility of paying for the aligner platform in installments.
              The specific details of the installment plans can be discussed
              with AlignSmile's customer support team. They will work with you
              to find a payment arrangement that suits your needs and ensures
              access to their high-quality aligner treatment.
            </div>
          </van-collapse-item>
          <van-collapse-item
            title="Q: Does AlignSmile's pricing include follow-up appointments and adjustments?"
            name="4"
          >
            <div class="data">
              A: AlignSmile understands that affordability is important to their
              patients. They offer flexible payment options, including the
              possibility of paying for the aligner platform in installments.
              The specific details of the installment plans can be discussed
              with AlignSmile's customer support team. They will work with you
              to find a payment arrangement that suits your needs and ensures
              access to their high-quality aligner treatment.
            </div>
          </van-collapse-item>
          <van-collapse-item
            title="Q: Is there a refund policy if I am not satisfied with AlignSmile's aligner platform?"
            name="5"
          >
            <div class="data">
              A: AlignSmile understands that affordability is important to their
              patients. They offer flexible payment options, including the
              possibility of paying for the aligner platform in installments.
              The specific details of the installment plans can be discussed
              with AlignSmile's customer support team. They will work with you
              to find a payment arrangement that suits your needs and ensures
              access to their high-quality aligner treatment.
            </div>
          </van-collapse-item>
        </van-collapse>
      </div>
    </div>
    <div class="bottom">
      <div class="bottom_div container-xl">
        <div>
          <img
            style="width: 135px"
            src="https://beame.oss-accelerate.aliyuncs.com/mobilePicture/America_23.png"
            alt=""
          />
        </div>
        <div style="text-align: left">
          <p style="margin-bottom: 10px">Home</p>
          <p style="margin-bottom: 10px">Company</p>
          <p style="margin-bottom: 10px">Platform</p>
          <p style="margin-bottom: 10px">Pricing</p>
        </div>
        <div style="text-align: left">
          <img
            style="width: 60px"
            src="https://beame.oss-accelerate.aliyuncs.com/mobilePicture/America_24.png"
            alt=""
          />
          <p style="font-size: 20px; margin-top: 10px">+852 5388 7318</p>
        </div>
        <div style="text-align: left">
          <img
            style="width: 60px"
            src="https://beame.oss-accelerate.aliyuncs.com/mobilePicture/America_25.png"
            alt=""
          />
          <p style="font-size: 18px; margin-top: 10px">
            On the third floor of Luohu District, <br />
            Shenzhen City, Guangdong Province
          </p>
        </div>
      </div>
    </div>
  </div>
</template>
      <script>
export default {
  components: {},
  data() {
    return {
      activeName: "1", //默认打开第一个折叠面板
    };
  },

  created() {},
  mounted() {},
  methods: {
    Jump(url) {
      this.$router.push(url);
    },
  },
};
</script>
      <style scoped>
a:hover {
  color: #00e567 !important;
}

.navbar-dark .navbar-nav .nav-link {
  color: #000;
}
.navbar-collapse {
  flex-direction: row-reverse;
}
.navbar {
  margin-right: 30px;
}
.nav-item {
  padding: 0 15px;
}
.header {
  width: 100%;
  opacity: 1;
  background: rgba(204, 204, 204, 0);
  background-size: 100% 100%;
  text-align: center;
}

.header_div {
  width: 750px;
  margin: auto;
  position: absolute;
  top: 25%;
  left: 50%;
  transform: translate(-52%);
}
.header_h2 {
  font-size: 68px;
  font-weight: 600;
  margin-bottom: 30px;
}
.header_p {
  font-size: 22px;
}
.header_button {
  width: 400px;
  height: 56px;
  font-size: 28px;
  border-radius: 560px;
  border: none;
  color: #fff;
  margin-top: 30px;
  background: rgba(24, 163, 110, 1);
}

.header_button_2 {
  width: 240px;
  height: 56px;
  font-size: 28px;
  border-radius: 560px;
  margin-left: 30px;
  border: none;
  color: rgba(0, 214, 132, 1);
  margin-top: 30px;
  background: rgba(50, 62, 72, 1);
}
.evaluate {
  text-align: center;
  margin-top: 50px;
}

.evaluate_img_2 {
  width: 90%;
}

.confidence_free {
  padding: 80px 0 40px 0;
  text-align: center;
  display: flex;
  justify-content: center;
  flex-wrap: wrap;
}
.confidence_free_2 {
  padding: 80px 0 40px 0;
  text-align: center;
  display: flex;
  justify-content: center;
  background: rgba(70, 82, 98, 0.1);
}
.confidence_free_3 {
  width: 60%;
  margin: auto;
  margin-top: 60px;
  padding-top: 20px;
}
.confidence_free_div {
  margin: 30px;
}
.confidence_free_div_2 {
  width: 620px;
  text-align: left;
  margin: 40px 30px 30px 30px;
}
.confidence_free_img {
  width: 100%;
}
.confidence_free_h2 {
  margin-bottom: 30px;
  font-size: 30px;
}
.confidence_free_p {
  margin-top: 15px;
}
.confidence_free_button {
  width: 240px;
  height: 38px;
  border: none;
  margin-bottom: 15px;
  margin-top: 20px;
  color: #fff;
  border-radius: 560px;
  background: rgba(24, 163, 110, 1);
}
.confidence_free_h4 {
  font-size: 36px;
  margin: 80px 0px 30px 0px;
}
.Digital {
  text-align: center;
  /* background: url(https://beame.oss-cn-hongkong.aliyuncs.com/mobilePicture/America_8.png)
      no-repeat top left,
    url(https://beame.oss-cn-hongkong.aliyuncs.com/mobilePicture/America_9.png)
      no-repeat bottom right;
  background-size: 25em 25em; */
  /* margin-bottom: 100px; */
}
.Digital_h3 {
  font-size: 36px;
}
.summary {
  width: 100%;
  /* height: 720px; */
  background: rgba(204, 204, 204, 0);
  /* background-image: url();
  background-size: 100% 100%;
  text-align: center; */
}
.summary_h3 {
  font-size: 36px;
  font-weight: 700;
  letter-spacing: 0px;
  line-height: 42px;
  color: rgba(24, 163, 110, 1);
  text-align: left;
}
.summary_div {
  text-align: left;
  padding-top: 100px;
  padding-left: 190px;
}

.beVexed {
  text-align: center;
  padding: 90px 0 80px 0;
  background: rgba(70, 82, 98, 0.1);
}
.beVexed_title {
  color: rgba(24, 163, 110, 1);
}
.beVexed_title_2 {
  margin-bottom: 30px;
}
.beVexed_step {
  text-align: center;
  display: flex;
  justify-content: center;
}
.beVexed_step_div {
  width: 300px;
  border-radius: 32.64px;
  margin: 0 10px 40px 10px;
  padding: 50px 0;
}

.beVexed_step_img {
  width: 100%;
}
.beVexed_step_p {
  margin: auto;
  margin-top: -121px;
  width: 200px;
}
.beVexed_button {
  width: 384px;
  height: 48px;
  font-size: 24px;
  border: none;
  color: #fff;
  margin-bottom: 20px;
  border-radius: 560px;
  background: rgba(24, 163, 110, 1);
}
.query {
  width: 80%;
  margin: auto;
  text-align: center;
  padding: 60px 0 50px 0;
}
.confidence_title {
  font-size: 36px;
  margin: 80px;
}
.query_img {
  margin: 0 30px;
}
.data {
  margin-bottom: 10px;
  font-size: 17px;
}

.bottom {
  width: 100%;
  padding: 40px 0;
  margin-top: 60px;
  text-align: center;
  color: #fff;
  background: rgba(54, 176, 130, 1);
}
.bottom_div {
  text-align: center;
  display: flex;
  justify-content: space-around;
  align-items: stretch;

  background: rgba(54, 176, 130, 1);
}
.van-collapse-item >>> .van-cell {
  font-size: 20px !important;
  font-weight: 700;
}
.content {
  display: flex;
  align-items: center;
  font-weight: 700 !important;
}

.content > img {
  width: 20px;
}

.van-collapse-item >>> .van-cell {
  font-size: 20px !important;
  font-weight: 700;
  /* background: rgba(50, 62, 72, 0.08); */
  margin-top: 20px;
  border-radius: 10px;
  padding: 16px;
}
.van-collapse >>> .van-collapse-item {
  border-bottom: solid 1px rgba(24, 163, 110, 1);
  /* border-top: solid 1px #fff; */
}
.van-collapse-item >>> .van-collapse-item__title--expanded {
  border-radius: 0 0 !important;
  border-bottom: solid 1px rgba(24, 163, 110, 1);
  /* border-top: solid 1px rgba(24, 163, 110, 1); */
}

.van-collapse-item >>> .van-collapse-item__content {
  /* background: rgba(50, 62, 72, 0.08); */
  border-radius: 0 0 10px 10px;
}
.van-collapse-item >>> .van-cell__title {
  color: rgba(24, 163, 110, 1);
}
h2,
h3,
h4,
h5,
button,
span,
a {
  font-family: "Omnium-Bold";
}
p,
div {
  font-family: "Comfortaa-Bold";
}
</style>